import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Card, CardBody, Input, InputGroup, InputGroupAddon, Label, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Spinner } from 'reactstrap';
import {OmniApi} from '../customAPI/OmniApi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@iconify/react';
import dragIcon from '@iconify/icons-mdi/drag';
import trashCanOutline from '@iconify/icons-mdi/trash-can-outline';
import pencilOutline from '@iconify/icons-mdi/pencil-outline';
import pencilIcon from '@iconify/icons-mdi/pencil';
import deleteIcon from '@iconify/icons-mdi/delete';
import closeThick from '@iconify/icons-mdi/close-thick';
import arrowLeftRight from '@iconify/icons-mdi/arrow-left-right';
import plusIcon from '@iconify/icons-mdi/plus';
import { confirm } from "./Confirm";

const VariantDragInput = props => {

    const [addOptionModal, setAddOptionModal] = useState(false);
    

    const toggleAddOptionModal = () => setAddOptionModal(!addOptionModal);

    const {product}  = props;

    const history = useHistory();

    const handleOptionChange = (e, vrntToken, optnToken) => {
        let foundVariant = product.variants.find(v => v.variantToken === vrntToken)

        foundVariant.variantOptions[optnToken] = e.target.value;

        props.handleChange(product);
    }

    const handleVariantChange = (e, vrntToken) => {
        const target = e.target;
        const name = target.name;

        let foundVariant = product.variants.find(v => v.variantToken === vrntToken)

        foundVariant[name] = e.target.value;

        props.handleChange(product);
    }

    const deleteVariant = async (vrntToken) => {

        if (await confirm(`Deleted variants cannot be recovered. Do you want to proceed? `, "Deleting Variant...", "Delete Variant") === false) {
            return
        }

        OmniApi.delete(`variants/${vrntToken}`)
        .then(res => {
            let newProduct = {
                ...product,
                variants: product.variants.filter(v => v.variantToken !== res.data.token)
            }
            props.handleChange(newProduct);
        })
        .catch(err => alert('error', err))

    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        result.forEach((r, i) => r.sortOrder = i + 1)

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        if (source.droppableId !== destination.droppableId){
            return
        }

        let newProduct;

        if (source.droppableId === 'inputs'){
            const items = reorder(product.variants, source.index, destination.index);
            newProduct = {
                ...product,
                variants:[
                    ...items
                ]
            }
        }

        if (source.droppableId === 'options'){
            const items = reorder(product.prodOptions, source.index, destination.index);
            newProduct = {
                ...product,
                prodOptions:[
                    ...items
                ]
            }
        }

        // setProduct({
        //     ...product,
        //     variants:[
        //         ...items
        //     ]
        // }); 

        props.handleChange(newProduct)

    };

    const [newVariantLoading, setNewVariantLoading] = useState(false);

    function addVariant(){

        setNewVariantLoading(true)

        let newVariant = {
            prodToken: product.prodToken,
            sortOrder: product.variants?.length ?? 0
        }
        
        OmniApi.post('/variants', newVariant)
        .then(res => {
            // once new variant is successfully posted the full product with all the variants are refetched
            let newVariantToken = res.data.variantToken;
            OmniApi.get(`/products/${product.prodToken}/full`)
            .then(res => {
                // extract new variant from fetched product. Product is refetched in order to get the new variant with all the fields included. When POSTing a new variant the res doesn't include all required fields.

                let newVariant = res.data.variants.find(variant => variant.variantToken === newVariantToken);

                let productWithNewVariant = {
                    ...product
                }

                if(productWithNewVariant.variants === null)
                    productWithNewVariant.variants = [];

                productWithNewVariant.variants.push(newVariant);

                props.handleChange(productWithNewVariant, 'newVariant');
                
            })
        })
        .catch(err => alert('error', err))
        .finally(() => setNewVariantLoading(false))

    }

    const saveOption = (optionName) => {

        let newOption = {
            prodToken: product.prodToken,
            optionName,
            optionOrder: product.prodOptions.length
        }

        OmniApi.post('/productoptions', newOption)
        .then(res => {
            let newProduct = {
                ...product
            }

            newProduct.prodOptions.push({
                optionToken: res.data.optionToken,
                optionName: res.data.optionName
            })

            props.handleChange(newProduct, 'newOption')

            toggleAddOptionModal()
        })
        .catch(err => alert(err))
    }
    
    const editOption = (optionToken, optionName) => {

        let newProduct = {
            ...product
        }

        let newOption = newProduct.prodOptions.find(option => option.optionToken === optionToken)

        newOption.optionName = optionName;

        props.handleChange(newProduct)

    }

    const deleteOption = async (optionToken, optionName) => {

        if (await confirm(`Option '${optionName}' will be completely removed from this product. Would you like to proceed?`, "Delete Option", "Delete") === false){
            return
        }

        OmniApi.delete(`/productoptions/${optionToken}`)
        .then(res => {

            let newProduct = {
                ...product,
                prodOptions: product.prodOptions.filter(option => option.optionToken !== optionToken)
            }

            props.handleChange(newProduct)
        })
        .catch(err => alert(err));
    }


    const Option = props => {

        const [editOptionModal, setEditOptionModal] = useState(false);
        const [optionHover, setOptionHover] = useState(false);

        const toggleEditOptionModal = () => {
            if (!editOptionModal)
                setOptionHover(false);
            setEditOptionModal(!editOptionModal)
        };

        const { option, i } = props;

        return(
            <Draggable key={option.optionToken} 
                draggableId={option.optionToken} 
                index={i} 
                className="d-flex">
                    {(provided, snapshot) => (
                    <div ref={provided.innerRef} 
                    {...provided.draggableProps} 
                    onMouseEnter={() => setOptionHover(true)}
                    onMouseLeave={() => setOptionHover(false)}
                    className={`d-flex align-items-center justify-content-between px-0 d-flex col bg-light rounded text-truncate`} 
                    key={i}>
                        <div className="left-option-border rounded-left d-flex justify-content-center align-items-center"
                        {...provided.dragHandleProps}
                        style={{ minWidth: '35px', height: '100%', flexShrink: 0 }}
                        >
                            <Icon icon={arrowLeftRight} width="18" height="18"/>
                        </div>
                        <div style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <strong>{option.optionName}</strong>
                        </div>
                        <div style={{ width: '35px', height: '100%' }}>  
                        </div>
                        {optionHover &&
                        <div className="d-flex option-edit-delete align-items-center"> 
                            <div className="btn d-flex align-items-center p-0" onClick={toggleEditOptionModal}><Icon className="border rounded option-icon" icon={pencilIcon} width="21" height="21" /></div>
                            <div className="btn d-flex align-items-start p-0" onClick={() => deleteOption(option.optionToken, option.optionName)}><Icon className="border rounded option-icon" icon={closeThick} width="21" height="21" /></div>
                        </div>
                        }
                        <EditOptionModal modal={editOptionModal} toggle={toggleEditOptionModal} option={option} editOption={editOption} />
                        {/* <div className="d-flex justify-content-center align-items-center drag-option-handle rounded-circle"
                        {...provided.dragHandleProps}
                        ><Icon icon={arrowLeftRight} width="18" height="18"/></div> */}
                    </div>
                    )}
            </Draggable>
        )
    }

    if (product)
    return(
        <Card>
            <CardBody>
                <h4>Variants List</h4>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable className="d-flex" droppableId='options' direction="horizontal">
                        {(provided) => (
                            <Row>
                                <div className="d-flex col" {...provided.droppableProps} ref={provided.innerRef}>
                                <div style={{ width: '39px'}}></div>
                                <Col className="px-0 d-flex justify-content-center bg-light rounded">
                                    <Label className="d-flex ml-2 align-items-center my-1 font-weight-bold">Name</Label>
                                </Col>
                                    {product.prodOptions?.map((option, i) => (
                                        <Option option={option} i={i} key={option.optionToken} />
                                    ))}
                                {provided.placeholder}
                                <Col className="px-0 d-flex justify-content-center bg-light rounded">
                                    <Label className="d-flex ml-2 align-items-center my-1 font-weight-bold">SKU</Label>
                                </Col>
                                <Col className="px-0 d-flex justify-content-center bg-light rounded">
                                    <Label className="d-flex ml-2 align-items-center my-1 font-weight-bold">Price</Label>
                                </Col>
                                <div style={{ width: '39px'}}>
                                </div>
                                <div style={{ width: '39px'}}>
                                    <Button color="secondary" className="px-0" style={{ width: '39px' }} onClick={toggleAddOptionModal}><Icon icon={plusIcon} width="25" height="25"/></Button>
                                </div>
                                </div>
                                <AddOptionModal modal={addOptionModal} toggle={toggleAddOptionModal} saveOption={saveOption} />
                            </Row>
                        )}
                    </Droppable>
                </DragDropContext>
                <hr className="mt-0" />
                <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable className="d-flex" droppableId='inputs'>
                {(provided) => ( 
                    <Row>
                        <Col sm={12} className="d-flex">
                        <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-column" style={{flexGrow: '1'}}>
                        {product.variants?.sort((a, b) => a.sortOrder - b.sortOrder).map((variant, i) => (
                            <Draggable key={variant.variantToken} draggableId={variant.variantToken} index={i} className="d-flex">
                                {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="d-flex">
                            <InputGroup className="mb-1">
                                <InputGroupAddon {...provided.dragHandleProps} addonType="prepend">
                                    <span className="input-group-text py-0 px-1">
                                        <Icon icon={dragIcon} width="25" height="25" />
                                    </span>
                                </InputGroupAddon>
                                <Input 
                                placeholder="Name" 
                                name="variantName"
                                value={variant.variantName}
                                onChange={(e) => handleVariantChange(e, variant.variantToken)}
                                />
                                {product.prodOptions.map((option, i) => 
                                    <Input 
                                    key={option.optionToken} 
                                    value={variant.variantOptions[option.optionToken]}
                                    onChange={(e) => handleOptionChange(e, variant.variantToken, option.optionToken)}
                                    />
                                )}
                                <Input 
                                placeholder="SKU"
                                name="variantNumber"
                                value={variant.variantNumber}
                                onChange={(e) => handleVariantChange(e, variant.variantToken)}
                                />
                                <Input 
                                placeholder="Price"
                                name="variantPrice"
                                value={variant.variantPrice}
                                onChange={(e) => handleVariantChange(e, variant.variantToken)}
                                />
                                <InputGroupAddon addonType="append" 
                                onClick={() => history.push(`/omni/catalog/product/${product.prodToken}/${variant.variantToken}`)}>
                                    <span className="btn input-group-text py-0 px-1">
                                        <Icon icon={pencilOutline} width="25" height="25" />
                                    </span>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append">
                                    <span className="btn input-group-text py-0 px-1" 
                                    onClick={() => deleteVariant(variant.variantToken)}>
                                        <Icon icon={trashCanOutline} width="25" height="25" />
                                    </span>
                                </InputGroupAddon>
                            </InputGroup>
                            </div>)}</Draggable> 
                        ))} 
                        {provided.placeholder}
                        </div>
                        </Col>
                    </Row>
                )}
                </Droppable>
                </DragDropContext>
                <Row>
                    <Col className="d-flex">
                        {newVariantLoading ?
                        <Spinner size="sm" className='ml-3 mt-2'/>
                        :
                        <div className="btn" onClick={addVariant}><strong>+ Add Variant...</strong></div>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
    else return ('loading')
}

const AddOptionModal = props => {

    const [optionName, setOptionName] = useState('');

    const {modal, toggle} = props;

    const [nameError, setNameError] = useState(false);

    const saveOption = () => {
        if(!optionName)
            return setNameError(true);
        props.saveOption(optionName)
        // temporary!! deletes name after it is saved
        setOptionName('')
    }


    return(
        <Modal isOpen={modal} toggle={toggle} centered size="sm">
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Add Product Option
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Option Name</Label>
                <Input type="text" name="optionName" id="optionName" 
                className={`${nameError ? 'invalid' : ''}`}
                value={optionName}
                onChange={(e) => {
                    setNameError(false)
                    setOptionName(e.target.value)
                }}
                />
                {(nameError) && <div className="invalid-text">Option name is required</div>}
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => saveOption(optionName)}>
                    Save
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const EditOptionModal = props => {

    const {modal, toggle, editOption, option} = props;

    const [optionName, setOptionName] = useState(option.optionName);

    const [nameError, setNameError] = useState(false);

    const validateAndEdit = () => {
        if(!optionName)
            return setNameError(true);
        editOption(option.optionToken, optionName)
    }

    return(
        <Modal isOpen={modal} toggle={toggle} centered size="sm">
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Edit Product Option
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Option Name</Label>
                <Input type="text" name="optionName" id="optionName" 
                className={`${nameError ? 'invalid' : ''}`}
                placeholder="Option Name (Size, Color, Material)"
                value={optionName}
                onChange={(e) => {
                    setNameError(false)
                    setOptionName(e.target.value)
                }}
                />
                {(nameError) && <div className="invalid-text">Option name is required</div>}
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={validateAndEdit}>
                    Save
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default VariantDragInput;