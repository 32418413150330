import axios from 'axios';

export const OmniApi = axios.create({
    baseURL: `${process.env.REACT_APP_OMNI_API}`
})

export const XOmniApi = axios.create({
    baseURL: `${process.env.REACT_APP_OMNI_API}`
})

// Customer Component = Customer name - count of notes tab / button / notes  - 
// no customr info heading
// add cancel to modal
// customer length hover show actual join date
//balance and credit limit in separate card customer wallet - remove edit
// Saved addresses or address book (( total address count = 5))
// clickable orders test that backwards works
// payment method name, brand, digits only hsow xses and last 4 didigits, exp, link billing address opens to small pop up
// add addresses utilized the most first