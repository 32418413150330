import React, { useEffect, useState } from 'react';
import { Table, CardBody, Card, Input, Button } from 'reactstrap';
import {OmniApi} from '../customAPI/OmniApi';
import { Icon } from '@iconify/react';
import dragIcon from '@iconify/icons-mdi/drag';

const VariantTable = () => {

    const [product, setProduct] = useState('');

    const [showRowInputs, setShowRowInputs] = useState('')

    useEffect(() => {
        OmniApi.get(`/products/prod_aiBtg1R4L14NhiIQ/full`)
        .then(res => setProduct(res.data))
    }, [])

    console.log('product', product)

    function onRowClick(e, row){
        console.log(e, row)
        setShowRowInputs({
            [row.variantToken]: true 
        })
    }

    if (product)
    return(
        <Card>
            <CardBody>

                <Table className="mb-0" hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            {product.prodOptions.map((option, i) => <th key={i}>{option.optionName}</th>)}
                            <th>SKU</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.variants.map((variant, i) => {
                            return (
                                <tr key={i} onClick={(e) => onRowClick(e, variant)}>
                                    {showRowInputs[variant.variantToken] ? 
                                    <>
                                    <th scope="row" className="p-0" style={{verticalAlign: 'middle'}}>
                                        <Button color="light" size="sm" className="px-0"><Icon icon={dragIcon} width="35" height="35" /></Button>
                                    </th>
                                    {product.prodOptions.map((option, i) => 
                                    <td key={i} className="py-1" style={{verticalAlign: 'middle'}}>
                                    <Input type="text" placeholder="text input" 
                                    value={variant.variantOptions[option.optionToken]}/>
                                    </td>
                                    )}
                                    <td className="py-0" style={{width: '20%', verticalAlign: 'middle'}}>
                                        <Input type="text" placeholder="text input" />
                                    </td>
                                    <td className="py-0" style={{width: '10%', verticalAlign: 'middle'}}>
                                        <Input type="text" placeholder="text input" value={variant.variantPrice} />
                                    </td>
                                    </>
                                    :
                                    <>
                                    <th scope="row" className="p-0" style={{verticalAlign: 'middle'}}>
                                        <Button color="light" size="sm" className="px-0"><Icon icon={dragIcon} width="35" height="35" /></Button>
                                    </th>
                                    {product.prodOptions.map((option, i) => 
                                    <td key={i}>{variant.variantOptions[option.optionToken]}</td>
                                    )}
                                    <td className="position-relative">SKU</td>
                                    <td className="position-relative" style={{width: '10%'}}>
                                        {variant.variantPrice}
                                    {/* {variant.variantPrice}     */}
                                    </td>
                                    </>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
    else
    return('loading')
}

export default VariantTable;