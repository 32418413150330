import React, { useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    UncontrolledTooltip
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageTitle from '../components/PageTitle';
import ImageUpload from './ImageUpload';
import { confirm } from "./Confirm";
import { OmniApi } from '../customAPI/OmniApi';
import { Helmet } from 'react-helmet';
import { UserContext } from '../custom-contexts/UserContext';

const BrandDetail = (props) => {

    const { user } = useContext(UserContext);

    let history = useHistory();

    const [brand, setBrand] = useState('')

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        let brandToken = props.match.params.brandToken;
        if (!brandToken){
            return setBrand({
                brandName: '',
                brandImage: ''
            })
        }

        OmniApi.get(`/brands/${brandToken}`)
        .then(res => {
            if (isEmpty(res.data))
                throw new Error('Invalid Brand Token')
            
            setBrand(res.data)
        })
        .catch(err => {
            props.history.replace('/omni/catalog/brands')
            toast.error(err.message)
        })

    }, [])

    const uploadImage = (img) => {
        let payload = new FormData();

        payload.append('ev_file', img);

        axios.post('https://fs1.cloudsnob.com/upload', payload, {
            headers: {
                'upload_token': user.company.fs1Token
              }
        })
        .then(res => setBrand({...brand, brandImage: res.data.path}))
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if(file){
            uploadImage(file);
        }
        else return
    }

    const deleteImage = async () => {
        if (await confirm("Are your sure you want to delete the brands 'Main Image'?", "Deleting Photo", "Delete")){
            setBrand({
                ...brand,
                brandImage: null
            });
        }
        else return
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        console.log('copied');
    }

    const [nameError, setNameError] = useState(false);

    const submitBrand = () => {
        if(!brand.brandName)
            return setNameError(true)

        let path = '/brands';
        if(brand.brandToken){
            path += ('/' + brand.brandToken);
        }

        let postBrand = OmniApi.post(`${path}`, brand)
        .then(res => {
            history.push(`/omni/catalog/brands`);
        })
        
        toast.promise(
            postBrand,
            {
                pending: {
                    render() {
                        return "Saving brand..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Brand successfully saved`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    const deleteBrand = async () => {

        if (await confirm(`Are you sure you want to delete '${brand.brandName}' from Brands?`, "Deleting Brand", "Delete Brand") === false) {
            return
        }

        let deleting = OmniApi.delete(`/brands/${brand.brandToken}`)
        .then(res => {
            history.push('/omni/catalog/brands');
        })

        toast.promise(
            deleting,
            {
                pending: {
                    render() {
                        return "Deleting Brand..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `${brand.brandName} successfully deleted`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    if(brand)
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Brand Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Brands', path: '/omni/catalog/brands' },
                    { label: 'Brand Detail', path: props.location.pathname, active: true },
                ]}
                title={brand.brandToken ? 'Edit Brand' : 'New Brand'}
            />
            <Form>
                    <Row>
                        <Col md={8}>
                            <Card>
                                <CardBody>
                                <FormGroup>
                                        <Label for="text">Brand Name</Label>
                                        <Input type="text" name="text" id="text" 
                                        value={brand.brandName} 
                                        className={`${nameError ? 'invalid' : ''}`}
                                        onChange={(e) => {
                                            setNameError(false)
                                            setBrand({...brand, brandName: e.target.value})
                                        }} 
                                        placeholder="Name" />
                                        {nameError ? <div className="invalid-text">Brand name is required</div> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                <Label for="Button">Brand Token</Label>
                                <InputGroup>
                                    <Input type="text" value={brand.brandToken || ''} disabled/>
                                    <InputGroupAddon addonType="append">
                                        <Button color="secondary" onClick={() => copyToClipboard(brand.brandToken)} id="copy-brandToken">Copy</Button>
                                        <UncontrolledTooltip placement="top" target="copy-brandToken" trigger="legacy">
                                            '{brand.brandToken}' copied to clipboard
                                        </UncontrolledTooltip>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="d-flex justify-content-center align-content-center">
                                    <ImageUpload 
                                    handleImageUpload={handleImageUpload} 
                                    image={brand.brandImage} 
                                    deleteImage={deleteImage}
                                    allowDelete={true}
                                    imageSize={'200'}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                        <div className="d-flex justify-content-end">
                        <Button 
                        className="btn btn-danger btn-lg mr-3"
                        onClick={() => deleteBrand()}
                        disabled={!brand.brandToken}
                        >
                                Delete
                            </Button>
                            <Button 
                            color="primary"
                            className="btn btn-lg"
                            onClick={() => submitBrand()}
                            >
                                Save
                            </Button>
                        </div>
                        </Col>
                    </Row>
            </Form>
        </>
    )
    else
    return(
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading brand...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
    )
}

export default BrandDetail;