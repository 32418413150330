import React from 'react';
import { Helmet } from 'react-helmet';

const Reports = () => {
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Reports | {process.env.REACT_APP_HEADER_TITLE}</title>
        </Helmet>
        <h2>Reports coming soon</h2>
        </>
    )
}

export default Reports;