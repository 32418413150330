import React, { useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody
    // CustomInput,
    // Form,
    // FormGroup,
    // Label,
    // Input,
    // FormText,
    // InputGroup,
    // InputGroupAddon,
    // UncontrolledButtonDropdown,
    // Button,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
// import { select } from '@redux-saga/core/effects';
import axios from 'axios';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { UserContext } from '../custom-contexts/UserContext';
import { Helmet } from 'react-helmet';
import { OmniApi } from '../customAPI/OmniApi';

const RemotePagination = ({ data, columns, page, sizePerPage, onTableChange, totalSize, showTotal = true, paginationTotalRenderer }) => {
    const { ExportCSVButton } = CSVExport;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    // The onCLick in the row event routes the user to the brand detail page when row is clicked on
    const rowEvents = {
        onClick: (e, row) => {

            const location = {
                pathname: `/omni/customer/${row.customerToken}`,
                state: {
                    row
                }
            }

            history.push(location)
        }
    }

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };

    const [searchTerm, setSearchTerm] = useState('helo');

    const searchChange = (e) => {
        let result = e.target.value
        let page = parseInt(query.get('page'));
        if (page > 1)
            query.set('page', '1')
        query.set('searchTerm', result);
        history.push(location.pathname + "?" + query.toString());
    }

    useEffect(()=>{
        let result = query.get('searchTerm')
        setSearchTerm(result);
    }, [location.search])

    const [selectedRows, setSelectedRows] = useState([]);

    const selectRow = {
        mode: 'checkbox',
        style: { background: '#CCD0FB' },
        clickToSelect: false,
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect){
                setSelectedRows(rows.map(e => e.prodToken));
            } else {
                setSelectedRows([]);
            }
          },
        onSelect: (row, isSelect, rowIndex, e) => {
            // console.log(row, isSelect, rowIndex);
            if (isSelect){
                setSelectedRows([...selectedRows, row.prodToken]);
            } else {
                setSelectedRows(selectedRows.filter(e => e !== row.prodToken));
            }
          },
        selectionRenderer: ({ checked, disabled }) => {
            return (
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input row-checkbox"
                        checked={checked}
                        disabled={disabled}
                        onChange={() => {}}
                    />
                    <label
                        className="custom-control-label row-checkbox-label"
                        onClick={e => {
                            e.preventDefault();
                        }}></label>
                </div>
            );
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
            return (
                <div className="custom-control custom-checkbox select-header">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        ref={input => {
                            if (input) input.indeterminate = indeterminate;
                        }}
                        {...rest}
                        onChange={() => {}}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                        onClick={e => {
                            e.preventDefault();
                        }}></label>
                </div>
            );
        },
    };

    console.log('selected', selectedRows)

    return (
      <ToolkitProvider
        bootstrap4
        keyField="customerToken"
        data={data}
        columns={columns}
        exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
        {props => (
            <React.Fragment>
                <Row className="mb-2">
                    <Col className="text-right form-inline justify-content-end">
                        <ExportCSVButton {...props.csvProps} className="export-csv-btn btn btn-primary mr-3">
                            Export CSV
                        </ExportCSVButton>
                        <input className="form-control" placeholder='Search customers' value={searchTerm} onChange={(e) => searchChange(e)}/>
                    </Col>
                </Row>

                <BootstrapTable
                    {...props.baseProps}
                    remote
                    bordered={false}
                    headerClasses="thead-light"
                    // defaultSorted={ defaultSorted }
                    pagination={ paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer, showTotal }) }
                    wrapperClasses="table-responsive"
                    classes="order-table"
                    bordered={ false }
                    rowEvents={ rowEvents }
                    // selectRow={ selectRow }
                    rowClasses={ rowClasses }
                    hover
                    onTableChange={ onTableChange }
                    // noDataIndication={() => 'No results...'}
                />
            </React.Fragment>
        )}
    </ToolkitProvider>
  );
  }

const Customers = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // const [selectedRows, setSelectedRows] = useState([]);
    const [products, setProducts] = useState([]);

    const [paginationValues, setPaginationValues] = useState({
        page: null,
        data: [],
        sizePerPage: null,
        sortOrder: null,
        sortField: null,
        totalSize: null
    })

    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    // useEffect(() => {
    //     const page = parseInt(query.get('page'));
    //     const sizePerPage = parseInt(query.get('sizePerPage'));
    //     const currentIndex = (page - 1) * sizePerPage;

    //     setPaginationValues({
    //         ...paginationValues,
    //         data: products ? products.slice(currentIndex, currentIndex + sizePerPage) : ''
    //     })
    // }, [products])

    // useEffect(() => {
    //     let userToken = localStorage.getItem('OMNI_TOKEN');

    //     axios.get(`https://dev.api.omni.cloudsnob.com/users/${userToken}`, {
    //         headers: {
    //           'token': userToken
    //         }
    //     })
    //     .then(res => setUser(res.data));  
    //   }, []);

    // Set default pagination query params if none are existent
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         console.log('query', query)
    //         history.push(location.pathname + "?" + query.toString());
    //     }
    // }, [])

    const customerStatuses = [
        {
            "id": "cs_awaiting_approval",
            "name": "Awaiting Approval"
        },
        {
            "id": "cs_reg_customer",
            "name": "Regular Customer"
        },
        {
            "id": "cs_crazy",
            "name": "Crazy Customer"
        },
        {
            "id": "cs_toplevel",
            "name": "Top Level Customer"
        },
        {
            "id": "cs_wholesale",
            "name": "Wholesale Customer"
        },
        {
            "id": "cs_banned",
            "name": "Banned Customer"
        },
        {
            "id": "cs_fraudulent",
            "name": "Fraudulent Customer"
        },
        {
            "id": "cs_spam",
            "name": "Spam Customer"
        }
    ]

    const columns = [
        {
            dataField: 'customerLastName',
            text: 'Name',
            sort: true,
            formatter: (cell, row) => {
                if (row.customerFirstName && row.customerLastName)
                return `${row.customerFirstName} ${row.customerLastName}`
                else
                return <div style={{ opacity: 0.5 }}><strong><i>**Guest**</i></strong></div>
            },
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'customerEmail',
            text: 'Email',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'customerStatus',
            text: 'Status',
            sort: true,
            style: { verticalAlign: 'middle', fontWeight: 'bold'  },
            formatter: (cell) => customerStatuses.find(status => status.id === cell)?.name
        },
        {
            dataField: 'dateCreated',
            text: 'Date',
            sort: true,
            formatter: (cell) => {
                let date = epochToLocal(cell);
                return moment(date).format('ll');
            },
            style: { verticalAlign: 'middle'}
        }
    ];

    const epochToLocal = (epoch) => {
		var d = new Date(0);
		return d.setUTCSeconds(epoch);
	}

    // const selectRow = {
    //     mode: 'checkbox',
    //     style: { background: '#5bc0de' },
    //     clickToSelect: false,
    //     onSelectAll: (isSelect, rows, e) => {
    //         if (isSelect){
    //             setSelectedRows(rows.map(e => e.brandToken))
    //         } else {
    //             setSelectedRows([])
    //         }
    //       },
    //     onSelect: (row, isSelect, rowIndex, e) => {
    //         // console.log(row, isSelect, rowIndex);
    //         if (isSelect){
    //             setSelectedRows([...selectedRows, row.brandToken])
    //         } else {
    //             // this.setState({ selectedRows: this.state.selectedRows.filter(e => e !== row.brandToken) });
    //             setSelectedRows(selectedRows.filter(e => e !== row.brandToken))
    //         }
    //       },
    //     selectionRenderer: ({ checked, disabled }) => {
    //         return (
    //             <div className="custom-control custom-checkbox">
    //                 <input
    //                     type="checkbox"
    //                     className="custom-control-input row-checkbox"
    //                     checked={checked}
    //                     disabled={disabled}
    //                     onChange={() => {}}
    //                 />
    //                 <label
    //                     className="custom-control-label row-checkbox-label"
    //                     onClick={e => {
    //                         e.preventDefault();
    //                     }}></label>
    //             </div>
    //         );
    //     },
    //     selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
    //         return (
    //             <div className="custom-control custom-checkbox select-header">
    //                 <input
    //                     type="checkbox"
    //                     className="custom-control-input"
    //                     id="customCheck1"
    //                     ref={input => {
    //                         if (input) input.indeterminate = indeterminate;
    //                     }}
    //                     {...rest}
    //                     onChange={() => {}}
    //                 />
    //                 <label
    //                     className="custom-control-label"
    //                     htmlFor="customCheck1"
    //                     onClick={e => {
    //                         e.preventDefault();
    //                     }}></label>
    //             </div>
    //         );
    //     },
    // };


    // function getFS1Image(prodImage){
    //     return `https://fs1.cloudsnob.com/static/${user.company.fs1GetToken}/${prodImage}/50`
    // }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    // When pagination query params change, update the pagination values
    useEffect(() => {
        if (!location.search){
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'asc');
            query.set('sortField', 'customerFirstName');
            query.set('searchTerm', '');
            history.replace(location.pathname + "?" + query.toString());
        }

        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchQuery = query.get('searchTerm');

        
        OmniApi.get(`/customers${solrUrlParams(page, sizePerPage, sortOrder, sortField, searchQuery)}`)
        .then(res => {
            console.log('res', res)
            setPaginationValues({
                page,
                sizePerPage,
                sortOrder,
                sortField,
                data: res.data.docs,
                totalSize: res.data.numFound
            })
        })

    }, [location.search])

    const solrUrlParams = ( page, sizePerPage, sortOrder, sortField, searchQuery = '') => {
        console.log('params', page, sizePerPage, sortOrder, sortField, searchQuery);
        let startIndex = (page - 1) * sizePerPage;
        console.log('startindex', startIndex);

        const getQueryForBackend = (query) => {
            return `catchAll:"*${query
                .split(' ')
                .map((e) =>
                  e
                    .replace(/^[^A-Za-z0-9]|[^A-Za-z0-9]$/g, '')
                    .replace(/^\.|\.$/g, '')
                    .replace(/[^A-Za-z0-9 \.]/g, ' ')
                    .trim()
                    .replace(/ +/g, ' ')
                )
                .filter((e) => e !== '')
                .join('* *')}*"~2`;
        }
         // on customer page solr search has been cancelled since it is not working. This needs to be fixed
        return `/solr?start=${startIndex}&sort=${sortField}&${searchQuery ? `q=${getQueryForBackend(searchQuery)}&` : ''}rows=${sizePerPage}&sortOrder=${sortOrder}`.toString();
    }

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage}) => {
        if (type === 'pagination'){
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort'){
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        history.push(location.pathname + "?" + query.toString());
    }

    const { data, sizePerPage, page, totalSize } = paginationValues;
    console.log('numfound', totalSize);

    if(user){
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Customers | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <h2 className="page-title">
                Customers
            </h2>
            <Card className="mt-2">
                <CardBody>
                <RemotePagination
                    data={ data }
                    page={ page }
                    columns={columns}
                    sizePerPage={ sizePerPage }
                    totalSize={ totalSize }
                    onTableChange={ onTableChange }
                    paginationTotalRenderer={customTotal}
                    showTotal={true}
                />
                    </CardBody>
                    </Card>
                </>
        )
    } else {
        return(
            <h1>Loading</h1>
        )
    }
}

export default Customers;